<template>
  <ul class="list-group list-group-flush">
    <list-group-title-section-component :label="$t('fields.courses')"/>
    <list-group-item-component v-for="(detail, index) in dealDetails" v-bind:key="`detail-${index}`">
      <template slot="label">
        <div class="standard-label text-truncate">
          <div>{{ detail.detailable.title }}</div>
          <label-theme-component>{{ $t('fields.code') }}: {{ detail.detailable.code }}</label-theme-component>
        </div>
      </template>
      <template slot="value">
        <label-theme-component class="ml-auto">
          {{ detail.detailable.price | euro }}
        </label-theme-component>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "DealReadOnlyDataDetails",
  components: {LabelThemeComponent, OctoIcon, ListGroupItemComponent, ListGroupTitleSectionComponent},
  props: {
    dealDetails: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style scoped>

</style>
