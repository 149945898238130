import { render, staticRenderFns } from "./DealReadOnlyDataPayments.vue?vue&type=template&id=57b8abbe&scoped=true&"
import script from "./DealReadOnlyDataPayments.vue?vue&type=script&lang=js&"
export * from "./DealReadOnlyDataPayments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b8abbe",
  null
  
)

export default component.exports