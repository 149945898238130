<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-plain">
          <tabs centered square>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
                </div>
              </template>
              <octo-header-button :title="registry.surname + ' ' + registry.name"/>
              <registry-tab-pane :registry="registry" :key="`registry-${updateAfterMountedKey}`"/>
            </tab-pane>

            <tab-pane v-if="showLeads">
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.leads') }}</div>
                </div>
              </template>
              <registry-leads :registry="registry" :key="`registry-leads-${updateAfterMountedKey}`"/>
            </tab-pane>

            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('deal.deal') }}</div>
                </div>
              </template>
              <registry-deals :registry="registry" :key="`registry-deals-pane-${updateAfterMountedKey}`"/>
            </tab-pane>

            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('didactics.students') }}</div>
                </div>
              </template>
              <registry-students :registry="registry" :key="`registry-students-${updateAfterMountedKey}`"/>
            </tab-pane>

            <tab-pane v-if="showEnabledCertifications">
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.enabled_certifications') }}</div>
                </div>
              </template>
              <registry-enabled-certifications
                :registry="registry"
                :key="`registry-certifications-${updateAfterMountedKey}`"
              />
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import Registry from "@/models/registry";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import RegistryForm from "@/pages/Registries/forms/RegistryForm";
import RegistryHistory from "@/pages/Registries/components/RegistryHistory";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import RegistryLeads from "@/pages/Registries/components/RegistryLeads";
import RegistryDeals from "@/pages/Registries/components/RegistryDeals";
import {permissionService} from "@/util/permission-service";
import RegistryEnabledCertifications from "./components/RegistryEnabledCertifications";
import RegistryStudents from "@/pages/Students/components/RegistryStudents";

export default {
  name: "ShowPage",
  components: {
    RegistryStudents,
    RegistryEnabledCertifications,
    RegistryDeals,
    RegistryLeads,
    RegistryTabPane,
    RegistryForm,
    OctoEntityPhones,
    OctoEntityAddresses,
    OctoEntityEmails,
    OctoIcon,
    Tabs,
    TabPane,
    OctoHeaderButton,
    RegistryHistory,
  },
  data() {
    return {
      endpoints: endpoints,
      registry: this.$_.cloneDeep(Registry),
      updateAfterMountedKey: 1,
      headerButtons: [{
        label: 'save',
        onClick: 'onSaveRegistry'
      }],
    }
  },
  computed: {
    showLeads() {
      return permissionService.isAllowed([
        permissionService.CALL_CENTER_MANAGER_ROLE,
        permissionService.TEACHING_MANAGER_ROLE
      ]);
    },
    showEnabledCertifications() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },
  },
  mounted() {
    const registryId = this.$route.params.id;

    this.$fullLoading.show();
    this.$api.get(endpoints.REGISTRY_SHOW.replace('{id}', registryId))
      .then((resp) => {
        this.registry = resp?.data?.data;
        this.updateAfterMountedKey++;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
  }
}
</script>

<style scoped>

</style>
