<template>
  <card class="shadow" body-classes="standard-card-body">
    <ul class="list-group list-group-flush">
      <list-group-title-section-component :label="$t('deal.payments')"/>
    </ul>
    <el-table stripe :data="payments" :width="800">
      <el-table-column align="left" :label="$t('fields.reason')">
        <div slot-scope="{ row }" class="text-truncate small">
          {{ row.reason ? $t('deal.payment_reasons.' + row.reason) : '' }}
        </div>
      </el-table-column>
      <el-table-column align="left" :label="$t('fields.amount')">
        <div slot-scope="{ row }">
          {{ row.amount | euro }}
        </div>
      </el-table-column>
      <el-table-column align="center" :label="$t('fields.deadline')">
        <div slot-scope="{ row }">
          {{ row.deadline | date }}
        </div>
      </el-table-column>
      <el-table-column align="center" width="50">
        <div slot-scope="{ row }">
          <octo-icon icon="checked" type="success" v-if="row.status === paymentStatusConst.paid.value"/>
        </div>
      </el-table-column>
      <template slot="empty">
        {{ $t('datatable.no_result') }}
      </template>
    </el-table>
  </card>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Badge from "@/components/Badge";
import PaymentStatusConst from "../resources/paymentStatusConst";
import {Table, TableColumn} from "element-ui";

export default {
  name: "DealReadOnlyDataPayments",
  components: {
    Badge,
    LabelThemeComponent,
    OctoIcon,
    ListGroupItemComponent,
    ListGroupTitleSectionComponent,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      paymentStatusConst: PaymentStatusConst
    }
  },
  props: {
    payments: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style scoped>

</style>
