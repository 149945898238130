<template>
  <div class="row">
    <div class="col-12 col-md-3">
      <stats-card
        class="shadow"
        :title="String(lessons.length)"
        :sub-title="$t('datatable.total')"
        type="primary"
        icon="list"
      />
    </div>
    <div class="col-12 col-md-3">
      <stats-card
        class="shadow"
        :title="String(presences)"
        :sub-title="$t('didactics.presences')"
        type="success"
        icon="checked"
      />
    </div>
    <div class="col-12 col-md-3">
      <stats-card
        class="shadow"
        :title="String(absences)"
        :sub-title="$t('didactics.absences')"
        type="warning"
        icon="wrong"
      />
    </div>
    <div class="col-12 col-md-3">
      <stats-card
        class="shadow"
        :title="String(recoveries)"
        :sub-title="$t('didactics.recoveries')"
        type="info"
        icon="academic"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "@/components/Cards/StatsCard";

export default {
  name: "StudentLessonsRecap",
  components: {StatsCard},
  data() {
    return {
      lessons: []
    }
  },
  props: {
    classroomLessons: {
      type: Array,
      default: () => []
    },
    studentLessons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
    }),
    absences() {
      return this.$_.filter(this.$_.cloneDeep(this.lessons), lesson => lesson.studentPresence === false)?.length;
    },
    presences() {
      return this.$_.filter(this.$_.cloneDeep(this.lessons), lesson => {
        return lesson.studentPresence && lesson.type === 'standard'
      })?.length;
    },
    recoveries() {
      return this.$_.filter(this.$_.cloneDeep(this.lessons), lesson => {
        return lesson.studentPresence && lesson.type === 'recovery'
      })?.length;
    }
  },
  mounted() {
    const localClassroomLessons = this.$_.keyBy(
      this.$_.map(this.classroomLessons, (lesson) => {
        const lessonMatch = this.$_.find(this.studentLessons, {id: lesson.id});
        if (lessonMatch) {
          lesson.studentPresence = lessonMatch.studentPresence;
        }
        return lesson;
      }) || [], 'id');

    const localStudentLessons = this.$_.keyBy(
      this.$_.map(this.studentLessons, (lesson) => {

        return lesson;
      }) || [], 'id');

    this.lessons = this.$_.orderBy({
      ...localClassroomLessons,
      ...localStudentLessons,
    }, 'date');
  }
}
</script>

<style scoped>

</style>
