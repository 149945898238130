<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="$t('fields.date_in')"
          :value="student.date_in | date"
        />
        <list-group-item-component
          :label="$t('fields.date_out')"
          :value="student.date_out | date"
        />
        <list-group-item-component :label="$t('fields.status')">
          <template slot="value">
            <badge v-if="student.status"
                   :type="student.status | studentStatusColor"
                   class="text-uppercase"
            >
              {{ $t('didactics.student_status.' + student.status) }}
            </badge>
          </template>
        </list-group-item-component>
      </ul>
    </div>
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="$t('fields.deal')"
          :value="student.deal_id | udid"
        />
        <list-group-item-component
          :label="$t('fields.first_contact')"
          :value="student.first_contact | date"
        />
        <list-group-item-component
          :label="$t('fields.second_contact')"
          :value="student.second_contact | date"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import Badge from "@/components/Badge";
import Student from "@/models/student";

export default {
  name: "RegistryStudentData",
  components: {Badge, ListGroupTitleSectionComponent, ListGroupItemComponent},
  props: {
    student: {
      type: Object,
      default: () => _.cloneDeep(Student)
    },
  }
}
</script>

<style scoped>

</style>
