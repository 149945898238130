<template>
  <div v-observe-visibility="visibilityChanged">
    <octo-loader v-if="isLoading"/>
    <div class="container-fluid" v-if="selectedLead.id">
      <div class="row" v-if="!isLoading">
        <div class="col-12">
          <octo-header-button
            :buttons="headerButtons"
            :title="registry.surname + ' ' + registry.name"
            @onOpenLead="$router.push({name: 'leads.archive.show', params: {id : selectedLead.id }})"
          />
        </div>
        <div class="col-12">
          <lead-tab-pane :lead="selectedLead" :key="`lead-data-tab-${currentPage}`"/>
        </div>
      </div>
      <div class="row" v-if="!isLoading && leads.length > 1">
        <div class="col-12">
          <card class="card card-plain mb-1" body-classes="standard-card-body d-flex justify-content-center">
            <base-pagination class="mb-0" v-model="currentPage" :per-page="1" :total="leads.length"/>
          </card>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && !selectedLead.id" class="d-flex justify-content-center">
      <h4>{{ $t('registry.registry_does_not_have_associated_lead') }}</h4>
    </div>
  </div>
</template>

<script>
import {ObserveVisibility} from "vue-observe-visibility";
import Registry from "@/models/registry";
import {endpoints} from "@/routes/endpoints";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import BasePagination from "@/components/BasePagination";
import Lead from "@/models/lead";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {permissionService} from "@/util/permission-service";

export default {
  name: "RegistryLeads",
  components: {OctoHeaderButton, BasePagination, OctoLoader, LeadTabPane},
  directives: {
    'observe-visibility': ObserveVisibility
  },
  data() {
    return {
      leads: [],
      isLoading: false,
      currentPage: 1,
    }
  },
  props: {
    registry: {
      type: Object,
      default: () => this.$_.cloneDeep(Registry)
    }
  },
  computed: {
    selectedLead() {
      return this.leads[this.currentPage - 1] || this.$_.cloneDeep(Lead);
    },

    headerButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({
          onClick: 'onOpenLead',
          label: 'open'
        })
      }

      return buttons;
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible && this.leads.length === 0) {
        this.isLoading = true;
        this.loadLeads()
          .then((resp) => {
            this.leads = resp;
          })
          .catch((e) => {
            this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
          })
          .finally(() => {
            this.isLoading = false;
          })
      }
    },

    loadLeads: function () {
      return new Promise((resolve, reject) => {
        this.$api.get(endpoints.REGISTRY_GET_LEADS.replace('{id}', this.registry.id))
          .then((resp) => {
            resolve(resp?.data);
          })
          .catch((err) => {
            reject(err);
          })
      });
    }
  }
}
</script>

<style scoped>

</style>
