<template>
  <div v-observe-visibility="visibilityChanged">

    <octo-loader v-if="isLoading"/>
    <div class="row" v-show="students.length > itemsPerPage">
      <div class="col-12">
        <card class="card card-plain mb-1" body-classes="standard-card-body d-flex justify-content-center">
          <base-pagination v-model="currentPage" :per-page="itemsPerPage" :total="students.length"/>
        </card>
      </div>
    </div>
    <div class="row mb-3" v-if="selectedStudent.id">
      <div class="col-12">
        <card body-classes="standard-card-body" class="shadow mb-0">
          <div slot="header">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="card-title text-capitalize">
                <label-theme-component>{{
                    courses[selectedStudent.course_id] | optional('code')
                  }}:
                </label-theme-component>
                {{ courses[selectedStudent.course_id] | optional('title') }}
              </h4>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-title-section-component :label="$t('didactics.student')"/>
          </ul>
          <registry-student-data :student="selectedStudent" :key="`student-data-${currentPage}`"/>
          <octo-notes
            :readOnly="readOnlyNotes"
            entity="student"
            :key="`octo-notes-${currentPage}`"
            :entity-id="selectedStudent.id"
            :notes="selectedStudent.notes"
            @onSavedNotes="updateStudentNotes"
          />
          <ul class="list-group list-group-flush">
            <list-group-title-section-component :label="$t('fields.lessons')"/>
          </ul>
          <student-lessons-recap
            :key="`student-lessons-recap-${currentPage}`"
            :classroom-lessons="selectedStudent.mainClassroomLessons"
            :student-lessons="selectedStudent.lessons"
          />
          <student-lessons
            :classroom-lessons="selectedStudent.mainClassroomLessons"
            :student-lessons="selectedStudent.lessons"
            :key="`student-lessons-${currentPage}`"
          />
        </card>
      </div>
    </div>
    <no-data-component v-if="!selectedStudent && !isLoading" :label="$t('common.empty_list')"/>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import StudentStatusContainer from "@/pages/Students/components/StudentStatusContainer";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BasePagination from "@/components/BasePagination";
import NoDataComponent from "@/components/NoDataComponent";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import StudentLessons from "@/pages/Students/components/StudentLessons";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import Badge from "@/components/Badge";
import {ObserveVisibility} from "vue-observe-visibility";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Registry from "@/models/registry";
import OctoNotes from "@/components/OctoNotes";
import Student from "@/models/student";
import RegistryStudentData from "./RegistryStudentData";
import StudentLessonsRecap from "@/pages/Students/components/StudentLessonsRecap.vue";
import {permissionService} from "@/util/permission-service";

export default {
  name: "RegistryStudents",
  components: {
    StudentLessonsRecap,
    RegistryStudentData,
    OctoNotes,
    OctoHeaderButton,
    Badge,
    ListGroupTitleSectionComponent,
    StudentLessons,
    OctoLoader,
    NoDataComponent,
    BasePagination,
    ListGroupItemComponent,
    StudentStatusContainer,
    LabelThemeComponent
  },
  data() {
    return {
      students: [],
      currentPage: 1,
      isLoading: false,
      permissionService: permissionService,
    }
  },
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {
    registry: {
      type: Object,
      default: () => _.cloneDeep(Registry)
    },
    itemsPerPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),

    selectedStudent() {
      const student = this.students?.[this.currentPage - 1] || this.$_.cloneDeep(Student);

      student.mainClassroomLessons = this.$_.find(
        student.classrooms || {}, {student_classroom: 'main'}
      )?.lessons || [];

      return student;
    },
    readOnlyNotes() {
      return !this.permissionService.isAllowed([
        permissionService.TEACHING_MANAGER_ROLE,
        permissionService.ACCOUNTANT_ROLE,
      ])
    },
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible && this.students.length === 0) {
        this.isLoading = true;
        this.$api.get(endpoints.STUDENT_INDEX_BY_REGISTRY.replace('{id}', this.registry.id))
          .then((resp) => {
            this.students = resp?.data?.data || [];
          })
          .catch(() => {
            this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    updateStudentNotes(notes) {
      this.$set(this.students?.[this.currentPage - 1], 'notes', notes);
    }
  }
}
</script>

<style scoped>

</style>
