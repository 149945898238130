<template>
  <ul class="list-group list-group-flush">
    <list-group-title-section-component :label="$t('common.documents')"/>
    <list-group-item-component
      v-for="(media, index) in media"
      v-bind:key="`media-${index}`"
      :label="media.filename + '.' + media.extension"
    >
      <template slot="value">
        <a :href="media.url" target="_blank">
          <base-button link icon class="mx-1" size="sm">
            <octo-icon icon="download"/>
          </base-button>
        </a>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";

export default {
  name: "DealReadOnlyDataDocuments",
  components: {OctoIcon, ListGroupItemComponent, ListGroupTitleSectionComponent},
  props: {
    media: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style scoped>

</style>
