<template>
  <card class="shadow" body-classes="standard-card-body" header-classes="p-2 pb-0">
    <div slot="header">
      <slot name="header">
        <h4 class="card-title text-capitalize">{{ $t('deal.accounting_balance') }}</h4>
      </slot>
    </div>
    <ul class="list-group list-group-flush">
      <list-group-item-component
        :label="$t('fields.contract_amount')"
        :value="contractAmount | euro"
      />
      <list-group-item-component
        :label="$t('deal.total_payments_added')"
        :value="totalAdded | euro"
      />
      <list-group-item-component
        :label="$t('deal.total_payments_paid')"
        :value="totalPaid | euro"
      />
      <list-group-item-component
        :label="$t('deal.total_payments_remaining')"
        :value="totalRemaining | euro"
      />
      <list-group-item-component
        :label="$t('deal.total_payments_to_pay_')"
        :value="totalToPay | euro"
      />
    </ul>
  </card>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Deal from "@/models/deal";
import paymentStatusConst from "../resources/paymentStatusConst";
import _ from "lodash";
import {numeric} from "vee-validate/dist/rules";

export default {
  name: "DealPaymentSummary",
  components: {ListGroupItemComponent},
  props: {
    payments: {
      type: Array,
      default: () => []
    },
    contractAmount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    totalAdded() {
      let total = 0;

      this.$_.each(this.payments, payment => {
        total += Number(payment.amount);
      });

      return total;
    },
    totalPaid() {
      let total = 0;

      this.$_.each(this.payments, payment => {
        if (payment.status === paymentStatusConst.paid.value) {
          total += Number(payment.amount);
        }
      })

      return total;
    },
    totalRemaining() {
      return this.totalAdded - this.totalPaid;
    },
    totalToPay() {
      return this.contractAmount - this.totalPaid;
    },
  },
}
</script>

<style scoped>

</style>
