<template>
  <div>
    <ul class="list-group list-group-flush">
      <list-group-title-section-component :label="$t('deal.deal_data')"/>
      <list-group-item-component :label="$t('fields.date_in')" :value="deal.date_in | date"/>
      <list-group-item-component :label="$t('fields.date_out')" :value="deal.date_out | date" v-if="deal.date_out"/>
      <list-group-item-component :label="$t('fields.amount')" :value="deal.amount | euro"/>
      <list-group-item-component :label="$t('fields.accounting_status')" v-if="deal.accounting_status">
        <template slot="value">
          <badge :type="dealAccountingStatuses[deal.accounting_status].color" class="text-uppercase">
            {{ $t('deal.accounting_statuses.' + deal.accounting_status) }}
          </badge>
        </template>
      </list-group-item-component>
      <list-group-item-component
        :label="$t('fields.sale')"
        v-if="permissionService.isAllowed([
              permissionService.SALES_MANAGER_ROLE,
              permissionService.CALL_CENTER_OPERATOR_ROLE
            ])"
      >
        <template slot="value">
          <label-theme-component>
            {{ users[deal.user_id] | optional('firstname') }}
            {{ users[deal.user_id] | optional('lastname') }}
          </label-theme-component>
        </template>
      </list-group-item-component>
      <list-group-item-component v-if="deal.dealDates && deal.dealDates.length" :label="$t('deal.appointments')">
        <template slot="value">
          <div v-for="(dealDate, index) in deal.dealDates" v-bind:key="`deal-date-${index}`">
            <label-theme-component>
              {{ dealDate.date_start | date }}
            </label-theme-component>
          </div>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.classroom_info')" :value="deal.classroom_info"/>

      <list-group-title-section-component :label="$t('fields.notes')"/>
    </ul>

    <div class="p-2 white-space-pre">
      <label-theme-component>
        {{ deal.text }}
      </label-theme-component>
    </div>
  </div>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import Badge from "@/components/Badge";
import DealAccountingStatus from "../resources/dealAccountingStatus";

export default {
  name: "DealReadOnlyData",
  components: {Badge, OctoIcon, LabelThemeComponent, ListGroupItemComponent, ListGroupTitleSectionComponent},
  data() {
    return {
      permissionService: permissionService,
      dealAccountingStatuses: DealAccountingStatus
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    }
  },
  computed: {
    ...mapGetters({
      users: "common/users",
    }),
  }
}
</script>

<style scoped>

</style>
