<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button
        :title="registry.surname + ' ' + registry.name"
        :buttons="headerButtons"
        @onUpdateEnabledCertifications="updateEnabledCertifications"
      />
    </div>
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body">
        <div slot="header">
          <h4 class="card-title text-capitalize">{{ $t('common.enabled_certifications') }}</h4>
        </div>
        <list-selector-component
          :number-items-per-page="18"
          :list="enabledCertificationsLocal"
          ref="certificateSelector"
        >
          <template v-slot:default="slotProps">
            <div>
              <label-theme-component>{{ allCourses[slotProps.item.id] | optional('code') }}</label-theme-component>
              {{ allCourses[slotProps.item.id] | optional('title') }}
            </div>
          </template>
        </list-selector-component>
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {endpoints} from "@/routes/endpoints";
import Registry from "@/models/registry";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "RegistryEnabledCertifications",
  components: {LabelThemeComponent, ListSelectorComponent, OctoHeaderButton},
  data() {
    return {
      enabledCertificationsLocal: [],
      headerButtons: [{
        onClick: 'onUpdateEnabledCertifications',
        label: 'save_enabled_certifications'
      }]
    }
  },
  props: {
    registry: {
      type: Object,
      default: () => _.cloneDeep(Registry)
    }
  },
  computed: {
    ...mapGetters({
      certificates: 'common/certificates',
      allCourses: 'common/allCourses'
    })
  },
  mounted() {
    this.$_.each(this.$_.cloneDeep(this.certificates), (item) => {
      item.selected = !!this.$_.find(this.registry.enabled_certifications, certification => {
        return item.id === certification.id
      });

      this.enabledCertificationsLocal.push({id: item.id, selected: item.selected});
    });
  },
  methods: {
    updateEnabledCertifications() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.REGISTRY_ENABLED_CERTIFICATIONS.replace('{id}', this.registry.id),
        {certifications: this.$refs.certificateSelector.getAllItems()}
      )
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
