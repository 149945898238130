<template>
  <div v-observe-visibility="visibilityChanged">
    <octo-loader v-if="isLoading"/>
    <div class="container-fluid" v-if="selectedDeal.id">
      <div class="row" v-if="!isLoading">
        <div class="col-12">
          <octo-header-button
              :buttons="headerButtons"
              :title="registry.surname + ' ' + registry.name"
              @onOpenDeal="$router.push({name: 'deals.archive.show', params: {id : selectedDeal.id }})"
              @onOpenPostSelling="$router.push({name: 'post_selling.show', params: {id : selectedDeal.id }})"
          />
        </div>
        <div class="col-12 col-md-6">
          <card body-classes="standard-card-body" class="shadow">
            <deal-read-only-data :deal="selectedDeal"/>
          </card>
        </div>
        <div class="col-12 col-md-6">
          <deal-status-container :deal="selectedDeal" :key="`deal-status-${currentPage}`"/>
          <deal-history :deal="selectedDeal" :number-items-per-page="3" :key="`deal-history-${currentPage}`"/>
        </div>
      </div>
      <div class="row" v-if="!isLoading">
        <div class="col-12 col-md-6">
          <card body-classes="standard-card-body" class="shadow">
            <deal-read-only-data-details :deal-details="dealDetails"/>
            <deal-read-only-data-extra-info v-if="dealExtraInfo" :deal-extra-info="dealExtraInfo"/>
            <deal-read-only-data-documents :media="media"/>
          </card>
        </div>
        <div class="col-12 col-md-6" v-if="!isLoading">
          <deal-payment-summary :deal="selectedDeal" :key="`deal-payment-summary-${currentPage}`">
            <template slot="header">
              <ul class="list-group list-group-flush">
                <list-group-title-section-component :label="$t('deal.accounting_balance')"/>
              </ul>
            </template>
          </deal-payment-summary>
          <deal-read-only-data-payments :payments="payments" :key="`deal-payments-${currentPage}`"/>
        </div>
      </div>
      <div class="row" v-if="!isLoading && deals.length > 1">
        <div class="col-12">
          <card class="card card-plain mb-1" body-classes="standard-card-body d-flex justify-content-center">
            <base-pagination class="mb-0" v-model="currentPage" :per-page="1" :total="deals.length"/>
          </card>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && !selectedDeal.id" class="d-flex justify-content-center">
      <h4>{{ $t('registry.registry_does_not_have_associated_deal') }}</h4>
    </div>
  </div>
</template>

<script>
import {ObserveVisibility} from "vue-observe-visibility";
import Registry from "@/models/registry";
import {endpoints} from "@/routes/endpoints";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import BasePagination from "@/components/BasePagination";
import Deal from "@/models/deal";
import DealReadOnlyData from "@/pages/Deals/components/DealReadOnlyData";
import DealReadOnlyDataExtraInfo from "@/pages/Deals/components/DealReadOnlyDataExtraInfo";
import DealReadOnlyDataDetails from "@/pages/Deals/components/DealReadOnlyDataDetails";
import DealReadOnlyDataDocuments from "@/pages/Deals/components/DealReadOnlyDataDocuments";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealHistory from "@/pages/Deals/components/DealHistory";
import DealExtraInfo from "@/models/dealExtraInfo";
import DealPaymentSummary from "../../Deals/components/DealPaymentSummary";
import DealReadOnlyDataPayments from "../../Deals/components/DealReadOnlyDataPayments";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {permissionService} from "@/util/permission-service";

export default {
  name: "RegistryDeals",
  components: {
    OctoHeaderButton,
    ListGroupTitleSectionComponent,
    DealReadOnlyDataPayments,
    DealPaymentSummary,
    DealHistory,
    DealStatusContainer,
    DealReadOnlyDataDocuments,
    DealReadOnlyDataDetails,
    DealReadOnlyDataExtraInfo,
    DealReadOnlyData,
    BasePagination,
    OctoLoader
  },
  directives: {
    'observe-visibility': ObserveVisibility
  },
  data() {
    return {
      deals: [],
      isLoading: false,
      currentPage: 1,
    }
  },
  props: {
    registry: {
      type: Object,
      default: () => this.$_.cloneDeep(Registry)
    }
  },
  computed: {
    selectedDeal() {
      return this.deals[this.currentPage - 1] || this.$_.cloneDeep(Deal);
    },
    dealExtraInfo() {
      return this.selectedDeal.dealExtraInfo || this.$_.cloneDeep(DealExtraInfo);
    },
    media() {
      return this.selectedDeal.media || [];
    },
    dealDetails() {
      return this.selectedDeal.deal_details || [];
    },
    payments() {
      return this.selectedDeal.payments || [];
    },
    headerButtons() {
      const buttons = [];

      if (permissionService.isAllowed([
        permissionService.CALL_CENTER_MANAGER_ROLE,
        permissionService.ACCOUNTANT_ROLE
      ])) {
        buttons.push({
          onClick: 'onOpenDeal',
          label: 'open'
        })
        buttons.push({
          onClick: 'onOpenPostSelling',
          label: 'openPostSelling'
        })
      }

      return buttons;
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible && this.deals.length === 0) {
        this.isLoading = true;
        this.loadDeals()
            .then((resp) => {
              this.deals = resp;
            })
            .catch((e) => {
              this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
            })
            .finally(() => {
              this.isLoading = false;
            })
      }
    },

    loadDeals: function () {
      return new Promise((resolve, reject) => {
        this.$api.get(endpoints.REGISTRY_GET_DEALS.replace('{id}', this.registry.id))
            .then((resp) => {
              resolve(resp?.data);
            })
            .catch((err) => {
              reject(err);
            })
      });
    }
  }
}
</script>

<style scoped>

</style>
