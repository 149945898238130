import { render, staticRenderFns } from "./RegistryLeads.vue?vue&type=template&id=5ba02d54&scoped=true&"
import script from "./RegistryLeads.vue?vue&type=script&lang=js&"
export * from "./RegistryLeads.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ba02d54",
  null
  
)

export default component.exports