<template>
  <div>
    <ul class="list-group list-group-flush">
      <list-group-title-section-component :label="$t('deal.contract')"/>
    </ul>
    <ul class="list-group list-group-flush" v-if="dealExtraInfo.id">
      <list-group-item-component
        :label="$t('fields.confirmed_date')"
        :value="dealExtraInfo.confirmed_date | date"
      />
      <list-group-item-component
        :label="$t('fields.contract_amount')"
        :value="dealExtraInfo.contract_amount | euro"
      />
      <list-group-item-component
        :label="$t('fields.payment_type')"
        :value="dealExtraInfo.payment_type ? $t('deal.payment_types.' + dealExtraInfo.payment_type) : ''"
      />
      <list-group-item-component
        :label="$t('fields.installment_plan')"
        :value="dealExtraInfo.installment_plan === '1'
            ? $t('deal.single_installment')
            : `${dealExtraInfo.installment_plan} ${$t('deal.installments')}`"
      />
      <list-group-item-component
        :label="$t('fields.enrollment_amount')"
        :value="dealExtraInfo.enrollment_amount | euro"
      />
      <list-group-item-component
        :label="$t('fields.enrollment_payment_type')"
        :value="dealExtraInfo.enrollment_payment_type
            ? $t('deal.payment_types.' + dealExtraInfo.enrollment_payment_type)
            : ''"
      />
      <list-group-item-component
        :label="$t('fields.km_traveled')"
        :value="dealExtraInfo.km_traveled"
      />
      <list-group-item-component
        :label="$t('fields.consulting_in_presence')"
        :value="dealExtraInfo.consulting_in_presence ? $t('common.yes') : $t('common.no')"
      />
      <list-group-item-component
        :label="$t('fields.withheld_fee')"
        :value="dealExtraInfo.withheld_fee ? $t('common.yes') : $t('common.no')"
      />
    </ul>
  </div>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import DealExtraInfo from "@/models/dealExtraInfo";

export default {
  name: "DealReadOnlyDataExtraInfo",
  components: {OctoIcon, ListGroupItemComponent, ListGroupTitleSectionComponent},
  props: {
    dealExtraInfo: {
      type: Object,
      default: () => _.cloneDeep(DealExtraInfo)
    },
  },
}
</script>

<style scoped>

</style>
