var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-title-section-component',{attrs:{"label":_vm.$t('deal.payments')}})],1),_c('el-table',{attrs:{"stripe":"","data":_vm.payments,"width":800}},[_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.reason')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(row.reason ? _vm.$t('deal.payment_reasons.' + row.reason) : '')+" ")])}}])}),_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("euro")(row.amount))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.deadline')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.deadline))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.status === _vm.paymentStatusConst.paid.value)?_c('octo-icon',{attrs:{"icon":"checked","type":"success"}}):_vm._e()],1)}}])}),_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t('datatable.no_result'))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }