const PaymentStatusConst = {
  pending: {
    value: 'pending',
    color: 'primary',
  },
  expired: {
    value: 'expired',
    color: 'danger'
  },
  paid: {
    value: 'paid',
    color: 'success',
  },
  unpaid: {
    value: 'unpaid',
    color: 'danger',
  },
  lost: {
    value: 'lost',
    color: 'info',
  },
  credit_recovery: {
    value: 'credit_recovery',
    color: 'danger',
  },
  other: {
    value: 'other',
    color: 'info',
  },
}

export default PaymentStatusConst
